import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import TablePaginationActions from "../../presentational/table-pagination-actions";
import TablePagination from "@material-ui/core/TablePagination";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CircularProgress from "@material-ui/core/CircularProgress";
import Api from "../../../libraries/api";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { history } from "../../../shared/configure-store";
import MenuItem from "@material-ui/core/MenuItem";
import { toast } from "react-toastify";
import moment from "moment";
import DateFormat from "../../../libraries/data-format-helper";
import Tooltip from "@material-ui/core/Tooltip";
import ErrorIcon from "@material-ui/icons/Error";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

export default class SalesOrderedLists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      rows: [],
      sendPageNumber: "",
      sendPerPage: "",
      perPage: 50,
      currentPage: 0,
      currentPageTable: 0,
      totalData: 0,
      showDetail: false,
      search: "",
      purchaseData: [],
      userOrderedData: [], // Array for ordered items
      userShippedData: [], // Array for shipped items
      userDeliveredData: [], // Array for delivered items
      userDetails: [], // Array for delivered items
      tollTagIds: [],
      deliveryProvider: [],
      trackId: "",
      deliveryDays: 1,
      transponderShippedId: "",
      deliveryProviderId: "",
      selectedDeliveryProviderName: "",
      sortBy: "createdAt",
      sortOrder: "desc",
      start_date: null,
      end_date: null,
      dateError: false,
    };
    this.getPurchaseOrdered();
    this.handlePurchaseOrdered = this.handlePurchaseOrdered.bind(this);
    this.handleShippedOrder = this.handleShippedOrder.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.getPurchaseOrdered = this.getPurchaseOrdered.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e, prop) {
    const value = e.target.value;
    this.setState({ [prop]: value });
  }

  handleShippedOrder = () => {
    const { purchaseData } = this.state;

    let orderId = purchaseData.orderId;

    // Define the API endpoint
    const url =
      "https://prod.api.neoride.com/api/parking/v1/admin/transponder-order-shipped/" +
      orderId;
    const token = localStorage.getItem("access_token");

    // Perform the POST request
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Assuming token is stored in local storage
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          toast.success("Order delivered successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          // Close the dialog by updating showDetail
          this.setState({ showDetail: false });
          // Refresh the list by calling getPurchaseOrdered
          this.getPurchaseOrdered();
        } else {
          toast.error("Failed to deliver order.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        console.error("Error in shipping order:", error);
        toast.error("Error in shipping order", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  getPurchaseOrdered() {
    const {
      perPage,
      currentPage,
      search,
      sortBy,
      sortOrder,
      start_date,
      end_date,
    } = this.state;

    const token = localStorage.getItem("access_token");

    // Construct the API URL
    let url = `https://prod.api.neoride.com/api/parking/v1/admin/transponder-order?status=delivered&limit=${perPage}&page=${
      currentPage + 1
    }&sortBy=${sortBy}&sortOrder=${sortOrder}&search=${search}`;

    // Append start and end date if provided
    if (start_date) {
      url += `&start_date=${moment(start_date).format("YYYY-MM-DD")}`;
    }
    if (end_date) {
      url += `&end_date=${moment(end_date).format("YYYY-MM-DD")}`;
    }

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((resp) => {
        const data = resp.data || {}; // Adjust this based on the actual API response structure
        // Update state with fetched data
        this.setState({
          rows: data.orders || [],
          totalData: data.total || 0,
          loading: false,
        });
      })
      .catch((err) => {
        console.error("Error fetching transponder orders:", err);

        // Set error state in case of fetch failure
        this.setState({
          rows: [],
          totalData: 0,
          loading: false,
        });
      });
  }

  handleClose() {
    this.setState({ showDetail: false });
  }

  handlePurchaseOrdered(userId, orderId) {
    const token = localStorage.getItem("access_token");
    let url =
      "https://prod.api.neoride.com/api/parking/v1/admin/tag-orders-summary/" +
      userId;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((resp) => {
        const data = resp.data || {};
        let userOrderedData = data.orderDetails.ordered || [];
        let userShippedData = data.orderDetails.shipped || [];
        let userDeliveredData = data.orderDetails.delivered || [];
        let userDetails = data.orderDetails.userDetails || [];

        // Find the specific order record for the given orderId
        const orderDetails = [
          ...userOrderedData,
          ...userShippedData,
          ...userDeliveredData,
        ].find((order) => order.orderId === orderId);

        // Filter out the specific orderId from the shipped array
        userShippedData = userShippedData.filter(
          (order) => order.orderId !== orderId
        );

        // Set the state with the specific orderDetails and separate arrays
        this.setState(
          {
            showDetail: true,
            purchaseData: orderDetails || {}, // Single record for selected order
            userOrderedData, // Array for ordered items
            userShippedData, // Filtered array for shipped items
            userDeliveredData, // Array for delivered items
            userDetails,
          },
          () => {
            console.log("purchaseData =", this.state.purchaseData);
            console.log("userOrderedData =", this.state.userOrderedData);
            console.log("userShippedData =", this.state.userShippedData);
            console.log("userDeliveredData =", this.state.userDeliveredData);
          }
        );
      })
      .catch((err) => {
        console.error("Error fetching transponder orders:", err);
        this.setState({
          showDetail: false,
          purchaseData: {},
          userOrderedData: [],
          userShippedData: [],
          userDeliveredData: [],
          userDetails: [],
        });
      });
  }

  handleKeyPressSearch = (event) => {
    if (event.key === "Enter") {
      this.setState(
        {
          rows: [],
          currentPage: 0,
          currentPageTable: 0,
          loading: true,
        },
        () => {
          this.getPurchaseOrdered();
        }
      );
    }
  };

  handleDateChange(date) {
    this.setState({ start_date: date }, () => {
      this.getPurchaseOrdered(); // Fetch data with updated start_date
    });
  }

  handleEndDateChange(date) {
    if (this.state.start_date && moment(date).isBefore(this.state.start_date)) {
      this.setState({ dateError: true });
    } else {
      this.setState({ end_date: date, dateError: false }, () => {
        this.getPurchaseOrdered(); // Fetch data with updated end_date
      });
    }
  }

  // Handle sorting functionality
  handleSort = (field) => {
    if (field === "createdAt") {
      const { sortBy, sortOrder } = this.state;
      let newSortOrder = sortOrder === "asc" ? "desc" : "asc"; // Toggle sorting order
      this.setState(
        { sortBy: field, sortOrder: newSortOrder, loading: true },
        () => {
          this.getPurchaseOrdered();
        }
      );
    }
  };

  renderSortArrow = (field) => {
    const { sortBy, sortOrder } = this.state;
    if (sortBy === field) {
      return sortOrder === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    }
    return null; // No arrow if the field is not sorted
  };

  handleChangePage = (event, newPage) => {
    this.setState(
      {
        rows: [],
        currentPage: newPage,
        currentPageTable: newPage,
        loading: true,
      },
      () => {
        this.getPurchaseOrdered();
      }
    );
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        perPage: event.target.value,
        currentPage: 0,
        currentPageTable: 0,
        loading: true,
      },
      () => {
        this.getPurchaseOrdered();
      }
    );
  };

  handleDeliveryProviderChange = (e) => {
    if (this.state.loading) {
      console.warn("Data is still loading, please wait.");
      return;
    }

    const selectedProviderId = parseInt(e.target.value, 10); // Convert to integer here
    const selectedProvider = this.state.deliveryProvider.find(
      (provider) => provider.id === selectedProviderId
    );

    console.log("Selected Provider ID:", selectedProviderId);
    console.log("Selected Provider Obj:", this.state.deliveryProvider);
    console.log(
      "Selected Provider Name:",
      selectedProvider ? selectedProvider.vendor : ""
    ); // Update 'provider' to 'vendor' based on your object structure

    this.setState({
      deliveryProviderId: String(selectedProviderId), // Convert to string if necessary for state
      selectedDeliveryProviderName: selectedProvider
        ? selectedProvider.vendor
        : "", // Update based on 'vendor' key
    });
  };

  componentDidMount = async () => {
    const token = localStorage.getItem("access_token");
    try {
      const response = await fetch(
        "https://prod.api.neoride.com/api/parking/v1/admin/shipping-vendors",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resp = await response.json();

      if (resp.success && resp.data) {
        const { shippingVendors } = resp.data;
        this.setState(
          {
            deliveryProvider: shippingVendors || [],
            loading: false,
          },
          () => {
            console.log(
              "Delivery Providers set in state:",
              this.state.deliveryProvider
            );
          }
        );
      }
    } catch (error) {
      console.error("Error fetching delivery providers:", error);
      this.setState({ loading: false });
    }
  };

  render() {
    if (this.state.loading) {
      return <p>Loading providers...</p>; // Show a loading message until data is fetched
    }
    return (
      <div className="row">
        <div className="col-12 card">
          <div className="row align-items-center">
            <div className="col-md-4 search-bar">
              <TextField
                required
                type="text"
                id={"adornment-search-ordered"}
                name="search"
                placeholder="Search"
                onChange={(e) => this.handleChange(e, "search")}
                onKeyPress={this.handleKeyPressSearch}
                value={this.state.search}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="Search button"
                        onClick={this.handleClickSearch}
                      >
                        <i className="fas fa-search"> </i>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </div>
            <div className="col-md-2 filter">
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  margin="normal"
                  id="start_date_ordered"
                  label="Start Date"
                  format="MM/DD/YYYY"
                  value={this.state.start_date}
                  onChange={this.handleDateChange} // Start date change handler
                  KeyboardButtonProps={{ "aria-label": "change start date" }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div
              className="col-md-2 filter"
              style={{ display: "flex", alignItems: "center" }}
            >
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  margin="normal"
                  id="end_date_ordered"
                  label="End Date"
                  format="MM/DD/YYYY"
                  value={this.state.end_date}
                  onChange={this.handleEndDateChange} // End date change handler
                  KeyboardButtonProps={{ "aria-label": "change end date" }}
                  style={{
                    borderColor: this.state.dateError ? "red" : "initial",
                    borderWidth: this.state.dateError ? "2px" : "1px",
                    borderStyle: this.state.dateError ? "solid" : "none",
                  }}
                />
              </MuiPickersUtilsProvider>

              {/* Display tooltip with error icon if there's an error */}
              {this.state.dateError && (
                <Tooltip
                  title="End date cannot be earlier than start date"
                  placement="right"
                >
                  <ErrorIcon style={{ color: "red", marginLeft: "5px" }} />
                </Tooltip>
              )}
            </div>
          </div>
          {this.state.showDetail ? (
            <PurchaseOrderedDetail
              showDetail={this.state.showDetail}
              handleClose={this.handleClose}
              handleChange={this.handleChange}
              handleShippedOrder={this.handleShippedOrder}
              purchaseData={this.state.purchaseData}
              userOrderedData={this.state.userOrderedData} // Ordered items array
              userShippedData={this.state.userShippedData} // Shipped items array
              userDeliveredData={this.state.userDeliveredData} // Delivered items array
              userDetails={this.state.userDetails}
              trackId={this.state.trackId}
              deliveryDays={this.state.deliveryDays}
              deliveryProviderId={this.state.deliveryProviderId}
              selectedDeliveryProviderName={
                this.state.selectedDeliveryProviderName
              }
              getPurchaseOrdered={this.getPurchaseOrdered}
              handleTollTagChange={this.handleTollTagChange} // Pass as a pro
              tollTagIds={this.state.tollTagIds}
              handleDeliveryProviderChange={this.handleDeliveryProviderChange}
              deliveryProvider={this.state.deliveryProvider}
            />
          ) : (
            ""
          )}
          <div className="table-wrapper">
            <Table className="table-list mt-3" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Order Number</TableCell>
                  <TableCell>No. Of Tags</TableCell>
                  <TableCell>User ID</TableCell>
                  <TableCell>User Name</TableCell>
                  <TableCell>User Address</TableCell>
                  <TableCell onClick={() => this.handleSort("createdAt")}>
                    Request Date {this.renderSortArrow("createdAt")}
                  </TableCell>
                  <TableCell>Delivered Date</TableCell>
                </TableRow>
              </TableHead>
              {this.state.loading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={8} align="center" className="py-5">
                      <CircularProgress color="primary" />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <PurchaseOrderedTable
                  rows={this.state.rows}
                  handlePurchaseOrdered={this.handlePurchaseOrdered}
                />
              )}
            </Table>
          </div>
          <div>
            <TablePagination
              rowsPerPageOptions={[50, 75, 100]}
              component="div"
              count={this.state.totalData}
              rowsPerPage={this.state.perPage}
              page={this.state.currentPageTable}
              backIconButtonProps={{
                "aria-label": "previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </div>
        </div>
      </div>
    );
  }
}

class PurchaseOrderedTable extends Component {
  render() {
    let handlePurchaseOrdered = this.props.handlePurchaseOrdered;
    return (
      <TableBody>
        {this.props.rows.map(function (item, i) {
          return (
            <TableRow
              key={i}
              onClick={() =>
                handlePurchaseOrdered(item.user_id, item.transponder_order_id)
              }
            >
              <TableCell>{item.transponder_order_number}</TableCell>
              <TableCell>{item.transponder_order_no_of_tags}</TableCell>
              <TableCell>{item.user_id}</TableCell>
              <TableCell>{item.user_full_name}</TableCell>
              <TableCell>{item.user_full_address}</TableCell>
              <TableCell>
                {DateFormat.dateTime(item.transponder_order_date)}
              </TableCell>
              <TableCell>
                {DateFormat.dateTime(item.transponder_deliver_date)}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  }
}

class PurchaseOrderedDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deliveryProvider: [],
      verifyAddress: false,
      isVerifyA: false,
      loadingVerify: false,
      loadingCancel: false,
    };
  }

  render() {
    let handleClose = this.props.handleClose;
    let handleShippedOrder = this.props.handleShippedOrder;
    let purchase = this.props.purchaseData;
    let handleChange = this.props.handleChange;
    let userOrderedData = this.props.userOrderedData;
    let userShippedData = this.props.userShippedData;
    let userDeliveredData = this.props.userDeliveredData;

    let userDetails = this.props.userDetails;
    // Extract the tracking number from purchase.url
    const trackingNumber = purchase.url
      ? new URL(purchase.url).searchParams.get("trackNums")
      : "No tracking number";

    const addressParts = [
      purchase.address?.address,
      purchase.address?.address2,
      purchase.address?.city,
      purchase.address?.stateName,
    ];

    let formattedAddress = addressParts.filter(Boolean).join(", ");
    if (purchase.address?.postalCode) {
      formattedAddress += ` - ${purchase.address.postalCode}`;
    }

    return (
      <Dialog
        maxWidth={"md"}
        open={this.props.showDetail}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className="dialog-custom"
      >
        <DialogTitle id="form-dialog-title">
          <div className="row align-items-center">
            <div className="col-md-6">
              <span style={{ fontWeight: "bold" }}>Order</span>
              <span style={{ fontWeight: "bold" }}>
                {" "}
                #{this.props.purchaseData.orderNumber}
              </span>
              <span style={{ fontSize: "0.65em", marginLeft: "5px" }}>
                ({this.props.purchaseData.orderId})
              </span>
            </div>

            <div className="col-md-6">
              <p className="mb-0">
                <label className="title d-inline">Status</label>
                <i className="status text-capitalize"> Shipped</i>
              </p>
            </div>
          </div>
          <button onClick={handleClose} className="btn-close">
            <i className="fas fa-times"></i>
          </button>
        </DialogTitle>
        <DialogContent>
          <div>
            <div className="row">
              <div className="col-md-6">
                <label className="title">Account number</label>
                <p className="pl-2 pl-lg-3">{userDetails["userId"]}</p>
                <button
                  onClick={() =>
                    history.push("/accounts/detail/" + userDetails["userId"])
                  }
                  className="btn btn-blue-trans mb-3"
                >
                  Go to Account
                </button>

                <label className="title">Contact and Shipping</label>
                <p className="pl-2 pl-lg-3">
                  Name - {userDetails["userName"]}
                  <br />
                  Phone - {userDetails["userPhone"]}
                  <br />
                  Email - {userDetails["userEmail"]}
                  <br />
                  Address - {formattedAddress}
                </p>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <label className="title">Request Date</label>
                    <p className="pl-2 pl-lg-3">
                      {DateFormat.dateTime(moment(purchase.dateOrdered))}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <label className="title">Shipped Date</label>
                    <p className="pl-2 pl-lg-3">
                      {DateFormat.dateTime(moment(purchase.shippingDate))}
                    </p>
                  </div>

                  <div className="col-md-6">
                    <label className="title">Shipping Vendor</label>
                    <p className="pl-2 pl-lg-3">{purchase.shippingVendor}</p>
                  </div>
                  <div className="col-md-6">
                    <label className="title">Tracking URL</label>
                    <p className="pl-2 pl-lg-3 p-url">
                      <a
                        href={purchase.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {trackingNumber || "No tracking URL"}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Separator */}
            <hr className="section-separator" />

            {/* History Accordion */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="history-content"
                id="history-header"
              >
                <h4>History</h4>
              </AccordionSummary>
              <AccordionDetails
                style={{ maxHeight: "300px", overflowY: "auto", width: "100%" }}
              >
                <div style={{ width: "100%" }}>
                  {/* Check if all arrays are empty */}
                  {userOrderedData.length === 0 &&
                  userShippedData.length === 0 &&
                  userDeliveredData.length === 0 ? (
                    <h4 style={{ textAlign: "center" }}>No history found</h4>
                  ) : (
                    <>
                      {/* Ordered Items Table */}
                      {userOrderedData.length > 0 && (
                        <>
                          <h4>Ordered Items</h4>
                          <div className="table-wrapper">
                            <Table className="table-list mt-3" size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Order Number</TableCell>
                                  <TableCell>
                                    Transponders Serial Numbers
                                  </TableCell>
                                  <TableCell>Estimated Delivery</TableCell>
                                  <TableCell>Shipping Vendor</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {userOrderedData.map((item) => (
                                  <TableRow key={"ordered-" + item.orderId}>
                                    <TableCell>{item.orderNumber}</TableCell>
                                    <TableCell>
                                      {item.transponders
                                        ? item.transponders.join(", ")
                                        : "N/A"}
                                    </TableCell>
                                    <TableCell>
                                      {item.estimatedDelivery}
                                    </TableCell>
                                    <TableCell>
                                      {item.shippingVendor || "N/A"}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </div>
                        </>
                      )}

                      {/* Shipped Items Table */}
                      {userShippedData.length > 0 && (
                        <>
                          <h4>Shipped Items</h4>
                          <div className="table-wrapper">
                            <Table className="table-list mt-3" size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Order Number</TableCell>
                                  <TableCell>
                                    Transponders Serial Numbers
                                  </TableCell>
                                  <TableCell>Shipping Vendor</TableCell>
                                  <TableCell>Estimated Delivery</TableCell>
                                  <TableCell>Tracking URL</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {userShippedData.map((item) => (
                                  <TableRow key={"shipped-" + item.orderId}>
                                    <TableCell>{item.orderNumber}</TableCell>
                                    <TableCell>
                                      {item.transponders
                                        ? item.transponders.join(", ")
                                        : "N/A"}
                                    </TableCell>
                                    <TableCell>{item.shippingVendor}</TableCell>
                                    <TableCell>
                                      {item.estimatedDelivery}
                                    </TableCell>
                                    <TableCell>
                                      <a
                                        href={item.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {item.url || "No tracking URL"}
                                      </a>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </div>
                        </>
                      )}

                      {/* Delivered Items Table */}
                      {userDeliveredData.length > 0 && (
                        <>
                          <h4>Delivered Items</h4>
                          <div className="table-wrapper">
                            <Table className="table-list mt-3" size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Order Number</TableCell>
                                  <TableCell>
                                    Transponders Serial Numbers
                                  </TableCell>
                                  <TableCell>Delivery Date</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {userDeliveredData.map((item) => (
                                  <TableRow key={"delivered-" + item.orderId}>
                                    <TableCell>{item.orderNumber}</TableCell>
                                    <TableCell>
                                      {item.transponders
                                        ? item.transponders.join(", ")
                                        : "N/A"}
                                    </TableCell>
                                    <TableCell>
                                      {item.deliveryDate
                                        ? DateFormat.dateTime(
                                            moment(item.deliveryDate)
                                          )
                                        : "Pending"}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
